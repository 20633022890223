// copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

'use strict';

const utils = require('./utils/utils');

const ALLOWLIST = [
    'Cart-EditProductLineItem',
    'Cart-GetProduct',
    'Product-ShowQuickView',
    'Product-Variation'
];
const REFRESH_TRIGGERS = [
    'Cart-RemoveProductLineItem',
    'Cart-UpdateQuantity'
];

window.productCache = window.productCache || {};

/**
 * platform utility function for page context.
 * Splits the page-route to establish page context.
 *
*/
window.pageContext = (function pageContext () {
    try {
        const page = document.getElementsByClassName('page');
        const action = page.item(0).dataset.action;
        const [controller, route] = action.split('-')
        const controllerStr = controller.toLowerCase();
        const routeStr = route.toLowerCase();
        window.context = controller.toLowerCase();
        window.subcontext = route.toLowerCase();
        return controller.toLowerCase();
    }
    catch (e) {
        console.debug(`exception during pageContext utility, ${e}`);
        return;
    }
})();

/**
 * Method for executing updates on cart, lineitem, or product objects.
 * @param {object} event - javascript dom action
 * @param {string} action - controller-route formatted action 
 * @param {object} product - product object containing product pertinent data
 * @param {object} primeData - data object containing prime buyable specific data
 * 
 */
const update = (event, action, product, primeData) => {
    if (action === 'Cart-Show' || 
        action === 'Cart-RemoveProductLineItem' ||
        action === 'Checkout-Begin') {
        cartUpdate(primeData, product);
    } else if (action === 'Cart-EditProductLineItem' ||
        action === 'Cart-UpdateQuantity') {
        lineItemUpdate(primeData, product);
    } else if (action === 'Cart-GetProduct' ||
        action === 'Product-Show' ||
        action === 'Product-ShowQuickView' ||
        action === 'Product-Variation') {
        productUpdate(primeData, product);
    }
    return;
}

const shimmer = (event, action, product) => {
    if (action === 'Cart-Show' ||
        action === 'Checkout-Begin') {
        cartShimmer(product);
    } else if (action === 'Cart-EditProductLineItem' ||
        action === 'Cart-UpdateQuantity') {
        lineItemShimmer(product);
    } else if (action === 'Cart-GetProduct' ||
        action === 'Product-Show' ||
        action === 'Product-ShowQuickView' ||
        action === 'Product-Variation') {
        productShimmer();
    }
    return;
}

const productShimmer = () => {
    var $primeContainer;
    switch(pageContext) {
        case 'cart':
            $primeContainer = $('#editProductModal').find('.bwp-prime-container');
            break;
        case 'search':
            $primeContainer = $('#quickViewModal').find('.bwp-prime-container');
            break;
        default:
            $primeContainer = $('.bwp-prime-container');
    }

    $primeContainer.addClass('shimmer').removeClass('hidden');
}

/** 
  * inject the prime day promo percentage into the PDP when the master product is not selected
  * @param {object} product - product object containing prodcut specific data
  * 
 */
 const injectPrimeDayPromoPercentageToPDP = (product) => {
     // if the product is not a master product
     // inject the percentage off if the percentage off is valid
     if (
       product.hasPrimeExclusivePromotion &&
       product.hasPrimeExclusivePromotion.isBwpExclusive &&
       product.hasPrimeExclusivePromotion.isVariant &&
       !product.hasPrimeExclusivePromotion.isMasterProduct &&
       Number(product.hasPrimeExclusivePromotion.percentOff)
     ) {
       $(".buy-with-prime-prime-day-deal-badge-text-inject").html(
         `<p>${product.hasPrimeExclusivePromotion.percentOff}% off</p>`
       );
     } else {
       $(".buy-with-prime-prime-day-deal-badge-text-inject").html(``);
     }
 };

/** 
 * update bwp product card front end elements
 * @param {object} primeData - data object containing prime buyable specific data
 * @param {object} product - product object containing product specific data
 * 
*/
const productUpdate = (primeData, product) => {
    var $primeContainer;
    switch(pageContext) {
        case 'cart':
            $primeContainer = $('#editProductModal').find('.bwp-prime-container');
            break;
        case 'search':
            $primeContainer = $('#quickViewModal').find('.bwp-prime-container');
            break;
        default:
            $primeContainer = $('.bwp-prime-container');
    }

    injectPrimeDayPromoPercentageToPDP(product);
    
    var $primeButtonRow = $('.bwp-button-row-pdp');

    if (!$primeContainer.length) {
        $primeContainer = $('.bwp-prime-container');
    }

    if (!product.isPrimeBuyable || (!product.readyToOrder && product.productType !== 'master')) {
        $primeContainer.addClass('hidden').removeClass('shimmer');
        $primeButtonRow.addClass('hidden');
        return;
    }

    if (primeData) {
        if (product.productType === 'master' || primeData.isMain) {
            $primeContainer.find('.prime-delivery-estimate, .prime-brand-message, .bwp-delivery-fallback, .bwp-element-icons').addClass('hidden');
            $primeContainer.find('.bwp-main-product-message').removeClass('hidden');
            $primeContainer.removeClass('hidden').removeClass('shimmer');
            $primeButtonRow.addClass('hidden');
            return;
        }

        if (!primeData.delivery) {
            $primeContainer.find('.prime-delivery-estimate, .prime-brand-message, .bwp-main-product-message').addClass('hidden');
            $primeContainer.find('.bwp-easy-returns').removeClass('hidden');
            $primeContainer.find('.bwp-element-icons').removeClass('hidden');
            if (!primeData.returns || primeData.returns.title.includes('non-returnable')) {
                $primeContainer.find('.bwp-easy-returns').addClass('hidden');
            }
            $primeContainer.removeClass('shimmer');
            $primeButtonRow.addClass('hidden');
            return;
        }

        if (!primeData.returns) {
            $primeContainer.find('.bwp-easy-returns').addClass('hidden');
        }
        else if (primeData.returns.title.includes('non-returnable')) {
            $primeContainer.find('.bwp-easy-returns').addClass('hidden');
            $primeContainer.find('.bwp-not-returnable').removeClass('hidden');
        } else {
            $primeContainer.find('.bwp-not-returnable').addClass('hidden');
        }
    }

    if (!!primeData.delivery.errorCode) {
        $primeContainer.addClass('hidden').removeClass('shimmer');
        $primeButtonRow.addClass('hidden');
        return;
    }

    $primeContainer.find('.bwp-main-product-message').addClass('hidden');
    $primeContainer.find('.prime-delivery-estimate, .prime-brand-message, .prime-logo, .bwp-element-icons').removeClass('hidden');
    $primeContainer.find('.learn-more-link').removeClass('hidden');
    $primeContainer.removeClass('hidden').removeClass('shimmer');

    // Prime Promise is being shown, collect prime promise clickstream event.
    if (window.collectPrimePromiseEvent) {
        window.collectPrimePromiseEvent(primeData.delivery, $primeContainer);
        window.collectAddToCartClickEvent(primeData.delivery, $primeContainer);
    }

    $primeButtonRow.removeClass('hidden');

    if (primeData.delivery.messageText && primeData.delivery.messageText !== "Fast, FREE Delivery") {
        $primeContainer.find('.prime-delivery-estimate').text(primeData.delivery.messageText + '.');
    }
}

// update products interface elements at the line item level
const lineItemUpdate = (primeData, {UUID}) => {
    var $selector = $(`.card.uuid-${UUID}`);
    var $primeContainer = $selector.find('.bwp-prime-container');
    
    $primeContainer.find('.dr-text').addClass('hidden');

    // worry about delivery data first, most important
    if (primeData && primeData.delivery) {
        if (!!primeData.delivery.errorCode) {
            $primeContainer.addClass('hidden').removeClass('shimmer');
            return;
        }
        if (primeData.delivery.messageText.toLowerCase().indexOf('fast, free delivery') === -1) {
            $primeContainer.find('.prime-delivery-estimate')
                .text(primeData.delivery.messageText)
                .removeClass('hidden');
        }

        // return data handling
        if (primeData && !primeData.returns) {
            $primeContainer.find('.bwp-cart-delivery-under').removeClass('hidden');
        } else {
            if (primeData && primeData.returns.returnable) {
                $primeContainer.find('.bwp-delivery-and-returns').removeClass('hidden');
            } else {
                $primeContainer.find('.bwp-delivery-no-returns').removeClass('hidden');
            }
        }
        // Prime Promise is being shown, collect prime promise clickstream event.
        if (window.collectPrimePromiseEvent) {
            window.collectPrimePromiseEvent(primeData.delivery, $primeContainer);
        }
    }
    // if by chance we have return data, but not delivery, do this
    else if (primeData && primeData.returns) {
        if (primeData && primeData.returns.returnable) {
            $primeContainer.find('.bwp-cart-returns').removeClass('hidden');
        } else {
            $primeContainer.find('.bwp-delivery-no-returns').removeClass('hidden');
        }
        $primeContainer.removeClass('hidden');   
    }
    // if we don't have any data, no delivery, no return
    else {
        $primeContainer.find('.bwp-cart-delivery').removeClass('hidden');
        $primeContainer.removeClass('hidden');
    }
    
    $primeContainer.removeClass('shimmer');
}

// execute prime card shimmer effect at line item level
const lineItemShimmer = ({UUID}) => {
    var $selector = $(`.card.uuid-${UUID}`);
    var $primeContainer = $selector.find('.bwp-prime-container');
    $primeContainer.removeClass('hidden').addClass('shimmer')
}

// initiate update for products in cart
const cartUpdate = (primeData, {primeCartMap}) => {
    if (!Object.keys(primeData).length) {
        return productUpdate(primeData, product);
    }

    primeData.forEach(data => {
        var lineItems = primeCartMap[data.id];
        Object.keys(lineItems).forEach(lineItem => {
            var lineItemObj = lineItems[lineItem];
            lineItemUpdate(data, lineItemObj);
        })
    });
}

// initiate cart prime card shimmer effect
const cartShimmer = ({primeCartMap}) => {
     Object.keys(primeCartMap).forEach(id => {
        var lineItems = primeCartMap[id];
        Object.keys(lineItems).forEach(lineItem => {
            lineItemShimmer({UUID: lineItem});
        })
    }) 
}

/**
 * sets cache timeout
 * @returns
 */
const cacheUntil = (minutes) => {
    const until = new Date();
    until.setMinutes(until.getMinutes() + minutes);
    return until;
}

/**
 * function to return cache data for products
 * @param {*} res1 - first response object containing cache specific response data
 * @param {*} res2 - second response object containing cache specific response data
 * 
 */
const cacheResults = (res1, res2, product, action) => {
    if (product.productType === 'master') {
        productCache[product.id] = {
            isMain: true,
            primeBuyableVariants: product.primeBuyableVariants
        };
    }
    const products = window.pids;
    const cachedProducts = products.map((id) => {
        const idMatch = obj => obj.id === id;
        return productCache[id] = {
            id: id,
            delivery: res1.data.find(idMatch) || res1.errors.find(idMatch),
            returns: res2.data.find(idMatch) || res2.errors.find(idMatch),
            until: cacheUntil(res1.error ? 0 : 5)
        }
    })
    return (action === 'Cart-Show' || action === 'Checkout-Begin') ? cachedProducts : cachedProducts[0];
}

/**
 * creates service requests for prime products
 * @param {object} action - controller-route formatted action 
 * @param {object} product - product object containing product data
 * 
 */
const createRequests = (action, product) => {
    var isBatch = [
        'Cart-GetProduct',
        'Product-Show',
        'Product-ShowQuickView'
    ].includes(action);

    if (product.productType === 'standard') {
        isBatch = false;
    }
    if (product.productType === 'master') {
        isBatch = true;
    }

    var {delivery, returns} = product.primeActionUrls;
    
    var pids = 
    !isBatch ? 
    product.id : 
    (product.primeBuyableVariants || [product.id]);
    window.pids = Array.isArray(pids) ? pids : [pids];
    
    var qtys = 
    !isBatch ? 
    (product.selectedQuantity) : 
    Array(pids.length).fill(product.selectedQuantity).toString()

    const errorCallback = function (xhr, status) {
        console.error(`api ${status}: ${JSON.stringify(this)}`);
        return [{data: [], error: true, errors: [] }];
    }
    
    return {
        delivery: $.ajax({
            url: `${delivery}?pids=${pids}&qtys=${qtys}`,
            timeout: bwpTimeouts.delivery[pageContext],
        }).catch(errorCallback),
        returns: $.ajax({
            url: `${returns}?pids=${pids}`,
            timeout: bwpTimeouts.returns[pageContext],
        }).catch(errorCallback)
    }
}

/**
 * Method for initiating and renewing cache for bwp elements such as product and qty
 * @param {string} id - Product Id
 * @param {number} qty - Product Quantity
 * 
 */
const cacheCheck = (id, qty) => {
    var cache = productCache[id];
    if (!cache) {
        return;
    }
    if (cache.isMain) {
        return cache;
    }
    if (cache.until && cache.until < new Date()) {
        return;
    }
    if (cache.delivery.quantity !== qty) {
        return;
    }
    return cache;
}

/**
 * checks prime availability for product updates
 * @param {object} event - javascript dom action
 * @param {string} action - formatted url string
 * @param {object} product - product object containing product specific data
 * 
 */
const availability = (event, action, product) => {
    $(document).trigger('prime:shimmer', [action, product]);

    if (cacheCheck(product.id, product.selectedQuantity)) {
        $(document).trigger('prime:update', [action, product, productCache[product.id]]);
        return;
    }

    var {
        delivery, 
        returns
    } = createRequests(action, product);

    $.when(
        delivery, 
        returns
    ).done(([res1], [res2]) => {
        var primeData = cacheResults(res1, res2, product, action);
        $(document).trigger('prime:update', [action, product, primeData]);
    });
}

/**
 * filters products for prime buyable products and executes cart or pdp front end updates
 * @param {object} event - javascript dom action such as click
 * @param {object} xhr  - javascript class containing methods for transmitting HTTP requests
 * @param {object} status - status object containing data elements pertaining to request status
 * 
 */
const filterProductUpdates = function (event, xhr, status) {
    if (!xhr || xhr.status !== 200 || !['success', 'OK'].includes(xhr.statusText)) {
        return;
    }
    if (status && (status.dataType === 'html' || status.dataTypes.includes('html'))) {
        return;
    }

    var {
        action = '', 
        product = {}
    } = xhr.responseJSON;

    if (REFRESH_TRIGGERS.includes(action) && !$(".container.cart").find(".buy-with-prime-badge").length) {
        $("#bwp-cart-button").addClass('hidden');
    }

    if (!ALLOWLIST.includes(action)) {
        return;
    }
    if (!Object.keys(product).length) {
        var json = xhr.responseJSON;
        const querystring = json.queryString !== '' ? json.queryString : status.data;
        const searchParams = new URLSearchParams(querystring);
        const items = json.items || (json.cartModel && json.cartModel.items) || json.basket.items;

        items.some(item => item.isPrimeBuyable) ?
        $('.cart #bwp-cart-button').removeClass('hidden') :
        $('.cart #bwp-cart-button').addClass('hidden');
        
        product = items.find((item) => item.UUID === searchParams.get('uuid'))
        if (!product) {
            return;
        }

        product.readyToOrder = product.isOrderable;
        product.selectedQuantity = product.selectedQuantity || product.quantity;
        if (!product.isPrimeBuyable && pageContext === 'cart') {
            return;
        }
    }
    if (!product.isPrimeBuyable || (!product.readyToOrder && product.productType !== 'master')) {
        $(document).trigger('prime:update', [action, product, null])
        return;
    }

    $(document).trigger('prime:availability', [action, product]);
}



/**
 * executes batch calls when necessary
 * attaches required event handling for BWP for specific views/elements
 */
const onPageLoad = () => {

    // open modal when loaded
    $('#bwp-signup').modal();

    if (!pageContext || !['cart','product','checkout'].includes(pageContext)) {
        return;
    }

    var action;
    switch (pageContext) {
        case 'product':
            action = 'Product-Show';
            break;
        case 'checkout':
            action = 'Checkout-Begin';
            break;
        default:
            action = 'Cart-Show';
    }

    var product = bwpProduct;

    if (action === 'Product-Show' && (!product.isPrimeBuyable || (!product.readyToOrder && product.productType !== 'master'))) {
        return;
    }

    if (action === 'Cart-Show') {
        // forces refresh when fired event actions match one of the REFRESH_TRIGGERS
        $('body').on('cart:update', function (e, params) {
            if (REFRESH_TRIGGERS.includes(params.action)) {
                window.location.reload();
            }
        });

        // if prime eligibility error, sign user out
        if (window.location.href.indexOf('bwpcheckouterror=primeeligibility') !== -1) {
            utils.dynamicLoadJs(bwp.amazon.checkoutUrl).then(() => { 
                amazon.Pay.signout();
            });
        }
    }

    if ((action === 'Cart-Show' || action === 'Checkout-Begin') && (!product || !product.id || !product.id.length )) {
        return;
    }

    $(document).trigger('prime:availability', [action, product]);
}

// Event handler for shipping method updates to show/hide prime sign in as necessary
const onCheckoutLoad = () => {
    var currentStage = window.location.href;

    if (currentStage.includes('Checkout-Begin') || currentStage.includes('stage=shipping')) {
        $('body').on('shipping:updateShippingMethods', function(e, data) {
            var $el = $('#prime-card-shipping-sign-in');
            // check to make sure we have an element
            if ($el.length > 0) {
                if (data.shipping.showBWPSignIn) {
                    $el.removeClass('d-none');
                } else {
                    $el.addClass('d-none');
                }
            }
        });
    }

    // applying the appropriate bwp promo text after the "X% off" text in checkout
    // depending on if the use is on the initial checkout screen or the BwP checkout screen
    // an interval is required here since the ?bwp=true query string is not immediately applied
    let retryCount = 0;

    const intervalId = setInterval(() => {
      const isBwPCheckout = new URLSearchParams(window.location.search).get(
        "bwp"
      );
      const isPlaceOrderStage = currentStage.includes("stage=placeOrder");

      if (isBwPCheckout === "true" && isPlaceOrderStage) {
        // apply applied! text if on the BwP checkout screen
        $(".bwp-applied-checkout-inject").html(" applied!");
        clearInterval(intervalId); // Clear interval if successful
      } else {
        if (retryCount < 10) {
          retryCount++;

          if (retryCount > 1) {
            // apply alternate text if on the initial non-BwP checkout screen
            $(".bwp-applied-checkout-inject").html(
                " when you use Buy with Prime"
            );
          }
        } else {
          clearInterval(intervalId); // Clear interval after retries
        }
      }
    }, 50);
}

/**
 * listeners
 */
module.exports = (() => {
    $(document).on('ajaxComplete', filterProductUpdates);
    $(document).on('prime:availability', availability);
    $(document).on('prime:shimmer', shimmer);
    $(document).on('prime:update', update);
    onPageLoad();
    onCheckoutLoad();
})();
